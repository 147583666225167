declare let window: any
declare let __API_HOST__: string

import Promise from 'bluebird'
import $ from 'jquery'
import Cookie from 'js-cookie'
import _ from 'lodash'
import qs from 'query-string'

import * as logger from 'shared-libs/helpers/logger'

import { Logger } from 'browser/apis/logging'
import { Settings } from 'browser/app/models/settings'
import OverlayManager from 'browser/components/atomic-elements/organisms/overlay-manager/overlay-manager'

import { Entity } from 'shared-libs/models/entity'
import { RequestMethod, Store } from 'shared-libs/models/store'
import { getDeferredUser, getHealthStatusUrl } from 'shared-libs/helpers/utils'
import { clearCookie } from 'browser/app/utils/utils'

import { EntityDataSource } from 'browser/components/atomic-elements/organisms/entity/entity-data-source'
import { IApplicationStatusResponse } from 'browser/contexts/app-status/AppStatusContext'
import { IInboxFollowingResponse, IInboxTokenResponse, IMetabaseUrlResponse } from './interface'
import { PlatformType } from 'shared-libs/models/types/storyboard/storyboard-plan'

import { ImpersonateResponse } from 'shared-libs/generated/server-types/entity/action/user/password/impersonateResponse'
import { CompanyCodeSearchResult } from 'shared-libs/generated/server-types/library/fmcsa/companyCodeSearchResult'
import { LandingPageEntity } from 'shared-libs/generated/server-types/entity/sms/landingPageEntity'
import UploadWorker, { uploadFailed, uploadProgress, uploadSuccess } from './upload-worker'


type ProgressCallback = (value: number) => void
type HttpRequestCallback = (props: any) => void

// If true, use jQuery.ajax instead of the worker thread.
// Other parts of the code may depend on this, e.g. upload progress interstitial.
const USE_LEGACY_UPLOAD = false

class ApiImpl {
  private entityStore: Store
  private settings: Settings
  private uploadWorker: UploadWorker

  public useLegacyUpload = USE_LEGACY_UPLOAD
  public logger = logger.getLogger('Api')

  constructor() {
    this.entityStore = new Store(this, __API_HOST__, true)
    this.uploadWorker = new UploadWorker()
  }

  public setSettings(settings) {
    this.settings = settings
  }

  public getSettings() {
    return this.settings
  }

  public getHost() {
    return __API_HOST__
  }

  public get WEBAPP_URL(): string {
    return __API_HOST__.replace('api', 'app')
  }

  public get platform(): string {
    return PlatformType.WEB
  }

  public getProductName() {
    if (_.includes(window.location.host, 'loaddocs')) {
      return 'LoadDocs'
    }
    return 'Vector'
  }

  public getStore() {
    return this.entityStore
  }

  public setupAuthorization(authorization) {
    $.ajaxSetup({
      beforeSend: (jqXHR, settings) => {
        jqXHR.setRequestHeader('Authorization', authorization)
        jqXHR.url = settings.url
      },
      crossDomain: true,
    })

    this.uploadWorker.setupAuthorization(authorization)
  }

  public setLogger(newLogger: (message, args) => void) {
    logger.setLogger(this.logger, newLogger)
  }

  public getJSON<T>(url: string): Promise<T> {
    return new Promise((resolve, reject, onCancel) => {
      const request = $.ajax({
        crossDomain: true,
        error: reject,
        success: resolve,
        url,
      })
      onCancel(() => request.abort())
    })
  }

  public deleteJSON<T>(url): Promise<T> {
    return new Promise((resolve, reject, onCancel) => {
      const request = $.ajax({
        contentType: 'application/json',
        error: reject,
        method: RequestMethod.DELETE,
        processData: false,
        success: resolve,
        url,
      })
      onCancel(() => request.abort())
    })
  }

  public postJSON<T>(url, json, progressCallback?: ProgressCallback): Promise<T> {
    const options = {
      contentType: 'application/json',
      data: JSON.stringify(json),
      method: RequestMethod.POST,
      processData: false,
      url,
    }
    return this.sendRequest(options, progressCallback) as Promise<T>
  }

  public putJSON(url, json, progressCallback?: ProgressCallback) {
    const options = {
      contentType: 'application/json',
      data: JSON.stringify(json),
      method: RequestMethod.PUT,
      processData: false,
      url,
    }
    return this.sendRequest(options, progressCallback)
  }

  public putMultipart(url, formData, progressCallback: ProgressCallback) {
    return this.sendMultipartRequest(RequestMethod.PUT, url, formData, progressCallback)
  }

  public patchMultipart(url, formData, progressCallback: ProgressCallback) {
    return this.sendMultipartRequest(RequestMethod.PATCH, url, formData, progressCallback)
  }

  public sendMultipartRequest(
    method: RequestMethod,
    url: string,
    formData,
    progressCallback: ProgressCallback,
    jobTag: string = "",
    saveTaskId: string = null,
  ) {
    const options = {
      contentType: false,
      data: formData,
      method: method,
      processData: false,
      url,
      jobTag,
      saveTaskId,
    }
    return this.sendRequest(options, progressCallback)
  }

  public sendRequest(options, progressCallback: ProgressCallback) {
    if (!progressCallback) {
      progressCallback = _.noop
    }

    if (this.useLegacyUpload) {
      return this.legacySendRequest(options, progressCallback)
    }

    return this.uploadWorker.doApiSend(options, progressCallback)
  }

  private legacySendRequest(options, progressCallback: ProgressCallback) {
    const addProgressListener = (req) => {
      req.onprogress = (event) => {
        if (!event.lengthComputable) {
          return
        }
        const percentComplete = (event.loaded / event.total) * 100.0
        progressCallback(percentComplete)
      }
    }

    const request = $.ajax({
      ...options,
      xhr() {
        const xhr = $.ajaxSettings.xhr()
        if (progressCallback) {
          addProgressListener(xhr.upload)
        }
        return xhr
      },
      success() {
        if (progressCallback) {
          progressCallback(100)
        }
      },
    })

    return new Promise((resolve, reject, onCancel) => {
      request.done(resolve).fail(reject)
      onCancel(() => request.abort())
    })
  }

  // Adds the job to this.promises so we can get all the jobs
  // from a single source. Returns the assigned taskId.
  public addPendingJob(entity: any, promise: any) : string {
    return this.uploadWorker.addPendingJob(entity, promise)
  }

  public cancelPendingJob(jobId: string) {
    return this.uploadWorker.cancelPendingJob(jobId)
  }

  public pendingJobsForEntityId(entityId?: string): any[] {
    return this.uploadWorker.pendingJobsForEntityId(entityId)
  }

  public totalJobsForEntityId(entityId?: string): any[] {
    return this.uploadWorker.totalJobsForEntityId(entityId)
  }

  public subscribeToHTTPRequestProgress(callback: HttpRequestCallback) {
    return this.uploadWorker.addListener(uploadProgress, callback)
  }

  public subscribeToHTTPRequestSuccess(callback: HttpRequestCallback) {
    return this.uploadWorker.addListener(uploadSuccess, callback)
  }

  public subscribeToHTTPRequestFailure(callback: HttpRequestCallback) {
    return this.uploadWorker.addListener(uploadFailed, callback)
  }

  public postMagicCode(code) {
    return this.getOAuth2Token({
      client_id: '5ef37385aa06be9796df',
      client_secret: '10363edd64312d7f5c1e69112869eb10aa6ba5b6',
      code,
      grant_type: 'authorization_code',
      redirect_uri: 'http://loaddocs.co/#app',
    })
  }

  public postLogin(username, password) {
    return this.getOAuth2Token({
      client_id: '5ef37385aa06be9796df',
      client_secret: '10363edd64312d7f5c1e69112869eb10aa6ba5b6',
      grant_type: 'password',
      password,
      username: username.toLowerCase(),
    })
  }

  public getIsRegistered(identity) {
    return this.getJSON(
      `${__API_HOST__}/1.0/entities/actions/system/user/isRegistered?identity=${identity}`
    )
  }

  public getInviteById(id): Promise<any> {
    return this.getJSON(`${__API_HOST__}/1.0/entities/actions/system/user/inviteById?id=${id}`)
  }

  public getInviteByCode(code): Promise<any> {
    return this.getJSON(
      `${__API_HOST__}/1.0/entities/actions/system/user/inviteByCode?code=${code}`
    )
  }

  public getPendingInvites() {
    const url = `${__API_HOST__}/1.0/entities/actions/system/user/pendingInvites`
    return this.getJSON(url).then(({ invites, unverifiedInvites }) => {
      return {
        invites: _.map(invites, (json) => new Entity(json, this)),
        unverifiedInvites: _.map(unverifiedInvites, (json) => new Entity(json, this)),
      }
    })
  }

  public getDynamicLinkInvite(code, firstName, lastName, email, firmName, inviteId, companyCode) {
    return `${__API_HOST__}/1.0/entities/actions/system/dynamicLink?type=invite&accessCode=${code}&firstName=${firstName}&lastName=${lastName}&email=${email}&firm=${firmName}&inviteId=${inviteId}&companyCode=${companyCode}`
  }

  public getEmptyDynamicLinkInvite() {
    return `${__API_HOST__}/1.0/entities/actions/system/dynamicLink?type=invite`
  }

  public getDynamicLink(props) {
    const url = `${__API_HOST__}/1.0/entities/actions/system/dynamicLink`
    const deferredLink = new URL(url)

    deferredLink.searchParams.append('type', 'deferredLink')
    _.forEach(props, (value, key) => {
      const normalizedValue = _.isObject(value)
        ? encodeURIComponent(JSON.stringify(value))
        : encodeURIComponent(value)
      deferredLink.searchParams.append(key, normalizedValue)
    })


    return deferredLink.toString()
  }

  public resendInvite(entityId) {
    const url = `${__API_HOST__}/1.0/entities/actions/system/invite/resend`
    return this.postJSON(url, { entityId })
  }

  public register(body) {
    return this.postJSON(`${__API_HOST__}/1.0/entities/actions/system/user/register`, body)
  }

  public forgotPassword(identity) {
    const url = `${__API_HOST__}/1.0/entities/actions/system/user/forgotPassword`
    return this.postJSON(url, { identity })
  }

  public confirmVerificationCode(identity, code) {
    const url = `${__API_HOST__}/1.0/entities/actions/system/user/confirmVerificationCode`
    return this.postJSON(url, { identity, code })
  }

  public sendVerificationCode(identity) {
    const url = `${__API_HOST__}/1.0/entities/actions/system/user/sendVerificationCode`
    return this.postJSON(url, { identity })
  }

  public sendImpersonateRequest(targetUserId: string, identity: string) {
    const query = qs.stringify({
      id: targetUserId,
      identity
    })
    const url = `${__API_HOST__}/1.0/entities/actions/system/user/impersonate?${query}`
    return this.postJSON<ImpersonateResponse>(url, {})
  }

  public getUserByEmail(email) {
    const url = `${__API_HOST__}/1.0/entities/actions/system/user/query?email=${encodeURIComponent(
      email
    )}`
    return this.getJSON(url)
  }

  public migrateFirmDocuments(firmId, fromDocSchemaId, toDocSchemaId) {
    const url = `${__API_HOST__}/1.0/entities/actions/schemas/migrateFirmSchemas`
    return this.postJSON(url, {
      firm: firmId,
      fromSchema: fromDocSchemaId,
      toSchema: toDocSchemaId,
    })
  }

  public signout() {
    OverlayManager.closeAllOverlays()
    this.entityStore.clearCache()
    const authToken = Cookie.get('Authorization') || ''
    clearCookie('Authorization')
    // TODO: get client id and client secret from config
    const samlSessionIndex = Cookie.get('samlSessionIndex')
    if (samlSessionIndex == null) {
      return $.post(`${__API_HOST__}/1.0/entities/actions/system/oauth2/revoke`, {
        client_id: '5ef37385aa06be9796df',
        client_secret: '10363edd64312d7f5c1e69112869eb10aa6ba5b6',
        token: authToken.substr(7),
      })
    } else {
      this.removeSamlCookie();
      return $.post(`${__API_HOST__}/1.0/entities/actions/system/oauth2/revoke?samlSessionIndex=` + samlSessionIndex, {
        client_id: '5ef37385aa06be9796df',
        client_secret: '10363edd64312d7f5c1e69112869eb10aa6ba5b6',
        token: authToken.substr(7),
      })
    }
  }

  private removeSamlCookie() {
    const host = __API_HOST__;
    const domainParts = host.split(".");
    if(domainParts.length > 1) {
      const domain = domainParts[domainParts.length - 2] + "." + domainParts[domainParts.length - 1]
      document.cookie = 'Authorization=;Domain=' + domain + ';Path=/;Max-Age=0';
      document.cookie = 'samlSessionIndex=;Domain=' + domain + ';Path=/;Max-Age=0';
    } else {
      document.cookie = 'samlSessionIndex=;Path=/;Max-Age=0';
    }
  }

  public getActivityFeed(url = '/legacy/user/feed') {
    return this.getJSON(`${__API_HOST__}${url}`)
  }

  public getContacts() {
    return this.getJSON(`${__API_HOST__}/legacy/contacts`)
  }

  public getBundle() {
    return this.getJSON(`${__API_HOST__}/1.0/entities/actions/system/user/bundle`)
  }

  public getImagePreview(imageUri, transformations) {
    const url = `${__API_HOST__}/1.0/entities/actions/system/imagePreview`
    return this.postJSON(url, {
      transformations,
      type: 'image',
      uri: imageUri,
    })
  }

  public getLaneByEntityId(entityId) {
    const url = `${__API_HOST__}/1.0/entities/actions/lane/dispatchOrder`
    return this.postJSON(url, {
      lanePointBucketType: 'localityRegion',
      uniqueId: entityId,
    })
  }

  public getLaneById(laneId) {
    const url = `${__API_HOST__}/1.0/entities/actions/lane/identifier`
    return this.postJSON(url, { identifier: laneId })
  }

  public getMatch(payload) {
    const url = `${__API_HOST__}/1.0/entities/actions/system/user/register/match`
    return this.postJSON(url, payload)
  }

  public getRenameDownloadURI(link, name) {
    return `${link}?filename=${encodeURIComponent(name)}`
  }

  public getTestFtpStatus(): Promise<any> {
    const firmId = this.settings.getFirm().uniqueId
    return this.getJSON(
      `${__API_HOST__}/1.0/entities/actions/ftp/testConnection?firmId=${firmId}`
    ).then((result: any) => {
      return new Entity(result, apis)
    })
  }

  public getFirm(): Promise<any> {
    return this.getJSON(`${__API_HOST__}/1.0/entities/actions/system/firm/me`).then((firm) => {
      return new Entity(firm, apis)
    })
  }

  public getUser(): Promise<any> {
    return this.getJSON(`${__API_HOST__}/1.0/entities/actions/system/user/me`).then((user) => {
      return new Entity(user, apis)
    })
  }

  public getUsers(): Promise<any> {
    return this.getJSON(`${__API_HOST__}/legacy/users`)
  }

  public getPrintableDownloadUri(entityId): Promise<any> {
    return this.getJSON(`${__API_HOST__}/1.0/entities/actions/printable/render/uri/${entityId}`)
  }

  public getPrintablePreview(entityId): Promise<any> {
    return this.getJSON(
      `${__API_HOST__}/1.0/entities/actions/printable/renderPreview/${entityId}`
    ).then((result: any) => {
      return new Entity(result, apis)
    })
  }

  public getLandingPage(entityId): Promise<LandingPageEntity> {
    return this.getJSON(`${__API_HOST__}/1.0/entities/actions/system/landing/${entityId}`)
  }

  public batchDownloadDocuments({ attachments }) {
    Logger.logEvent('Batch Download')
    const url = `${__API_HOST__}/1.0/entities/actions/system/batchDownload`
    return $.ajax(url, {
      contentType: 'application/json',
      data: JSON.stringify({ attachments }),
      processData: false,
      type: RequestMethod.POST,
    }).then((json) => {
      const authToken = Cookie.get('Authorization').replace('Bearer ', '')
      return `${url}?access_token=${authToken}&token=${json.token}`
    })
  }

  public batchShareDocuments(entities, recipients, message) {
    Logger.logEvent('Batch Share')
    recipients = _.map(recipients, getDeferredUser)
    const url = `${__API_HOST__}/1.0/entities/actions/system/batchShare`
    return this.postJSON(url, { recipients, entities, message })
  }

  public batchPrintDocuments(entities) {
    const url = `${__API_HOST__}/1.0/entities/actions/system/batchPrint`
    return this.postJSON(url, { entities })
  }

  public batchTriggerPush(entities) {
    const url = `${__API_HOST__}/1.0/entities/actions/system/batchTriggerPush`
    return this.postJSON(url, { entities, ops: ['ftp', 'email', 'api'] })
  }

  public sendNotification({ attachments, message, recipients, subject }) {
    recipients = _.map(recipients, getDeferredUser)
    const url = `${__API_HOST__}/1.0/entities/actions/system/email/send`
    return this.postJSON(url, { attachments, message, recipients, subject })
  }

  // TODO(David): this should probably be Post. Get should not mutate data
  public getAPIHost() {
    return `${__API_HOST__}`
  }

  public datCredentialConnect(body) {
    const url = `${__API_HOST__}/1.0/entities/actions/tms/dat/credential/connect`
    return this.postJSON(url, body)
  }

  public datCapabilitiesLookup() {
    const url = `${__API_HOST__}/1.0/entities/actions/tms/dat/lookup/capabilities`
    return this.postJSON(url, {})
  }

  public datCredentialDisconnect() {
    const url = `${__API_HOST__}/1.0/entities/actions/tms/dat/credential/disconnect`
    return this.postJSON(url, {})
  }

  public datLoadBoardSync(orderEntityId, offerRate, comment) {
    const loadBoardEnabled = _.get(
      this.settings.getUserDATSettings(),
      'settings.isLoadBoardEnabled',
      false
    )

    if (!loadBoardEnabled) {
      return
    }

    const url = `${__API_HOST__}/1.0/entities/actions/tms/dat/loadBoard/sync`
    return this.postJSON(url, { entityId: orderEntityId, offerRate, comment })
  }

  public datLoadBoardRefresh(orderEntityId) {
    const loadBoardEnabled = _.get(
      this.settings.getUserDATSettings(),
      'settings.isLoadBoardEnabled',
      false
    )

    if (!loadBoardEnabled) {
      return
    }

    const url = `${__API_HOST__}/1.0/entities/actions/tms/dat/loadBoard/refresh`
    return this.postJSON(url, { entityId: orderEntityId })
  }

  public createQBOCustomer(customerId) {
    const url = `${__API_HOST__}/1.0/entities/actions/accounting/quickbooks/customer`
    return this.postJSON(url, { customerId })
  }

  public createQBOVendor(carrierId) {
    const url = `${__API_HOST__}/1.0/entities/actions/accounting/quickbooks/vendor`
    return this.postJSON(url, { carrierId })
  }

  public makeInvoicePayment(invoiceId, customerId, amount, date, notes) {
    const url = `${__API_HOST__}/1.0/entities/actions/accounting/quickbooks/payment`
    return this.postJSON(url, { invoiceId, customerId, amount, date, notes })
  }

  public datLoadBoardUnsync(orderEntityId) {
    const loadBoardEnabled = _.get(
      this.settings.getUserDATSettings(),
      'settings.isLoadBoardEnabled',
      false
    )

    if (!loadBoardEnabled) {
      return
    }

    const url = `${__API_HOST__}/1.0/entities/actions/tms/dat/loadBoard/unsync`
    return this.postJSON(url, { entityId: orderEntityId })
  }

  public datRateViewHistoric(laneId) {
    const rateViewEnabled = _.get(
      this.settings.getUserDATSettings(),
      'settings.isRateViewEnabled',
      false
    )
    if (!rateViewEnabled) {
      return
    }
    const url = `${__API_HOST__}/1.0/entities/actions/tms/dat/rateView/historic`
    return this.postJSON(url, { identifier: laneId })
  }

  public disconnectFromQuickBooksOnline(purgeQBSync) {
    const url = `${__API_HOST__}/1.0/entities/actions/accounting/quickbooks/disconnect`
    return this.postJSON(url, { purgeQBSync })
  }

  public clearQuickBooksOnlineSync() {
    const url = `${__API_HOST__}/1.0/entities/actions/accounting/quickbooks/clearQBSync`
    return this.getJSON(url)
  }

  public createSystemGeneratedDocument(brokerOrderId, documentViewId) {
    const url = `${__API_HOST__}/1.0/entities/actions/tms/createSystemGeneratedDocument`
    const timezone = this.settings.getTimezone()
    return this.postJSON(url, { brokerOrderId, documentViewId, timezone })
  }

  public exportBillToQuickBooksOnline(orderUniqueId) {
    const url = `${__API_HOST__}/1.0/entities/actions/accounting/quickbooks/bill`
    return this.postJSON(url, { uniqueId: orderUniqueId })
  }

  public exportInvoiceToQuickBooksOnline(orderUniqueId) {
    const url = `${__API_HOST__}/1.0/entities/actions/accounting/quickbooks/invoice`
    return this.postJSON(url, { uniqueId: orderUniqueId })
  }

  public makeBillPayment(billId, carrierId, amount, date, notes) {
    const url = `${__API_HOST__}/1.0/entities/actions/accounting/quickbooks/billpayment`
    return this.postJSON(url, { billId, carrierId, amount, date, notes })
  }

  public quickbooksAutoMapping(firm, entityType, integrationEntityId, hasUniqueIdAttached) {
    const url = `${__API_HOST__}/1.0/entities/actions/accounting/quickbooks/mapping`
    return this.postJSON(url, { firm, entityType, integrationEntityId, hasUniqueIdAttached })
  }

  public getRelatedOrders(invoiceId) {
    const url = `${__API_HOST__}/1.0/entities/actions/rbinvoice/findRelatedOrders`
    return this.postJSON(url, { invoiceId })
  }

  public getRelatedInvoices(orderId) {
    const url = `${__API_HOST__}/1.0/entities/actions/dispatchOrder/findRelatedInvoices`
    return this.postJSON(url, { orderId })
  }

  public checkIfOrdersContainRequiredDocuments(orderIds) {
    const url = `${__API_HOST__}/1.0/entities/actions/dispatchOrder/containsAllRequiredDocuments`
    return this.postJSON(url, { orderIds })
  }

  public exportCsv(payload) {
    const url = `${__API_HOST__}/1.0/entities/actions/export/csv`
    return this.postJSON(url, payload)
  }

  public getCsvTemplateHeaders(uniqueId) {
    const url = `${__API_HOST__}/1.0/entities/actions/system/csv/metadata?schemaId=${uniqueId}`
    return this.getJSON(url).then((json) => {
      return json['columns'].map((column) => {
        return column.name
      })
    })
  }

  public exportCommission(payload) {
    const url = `${__API_HOST__}/1.0/entities/actions/export/commission`
    return this.postJSON(url, payload)
  }

  public getNextSequence(schemaId, path) {
    const url = `${__API_HOST__}/1.0/entities/actions/system/sequence`
    return this.postJSON(url, {
      count: 1,
      path,
      schema: {
        entityId: schemaId,
      },
    })
  }

  public validateCompanyCode(companyCode) {
    const url = `${__API_HOST__}/1.0/entities/actions/system/user/register/companyCode`
    return this.postJSON(url, { companyCode })
  }

  public getCarrierSearchResults(query: string): Promise<CompanyCodeSearchResult[]> {
    if (query.length < 3) {
      return Promise.resolve([])
    }
    const baseUrl = `${__API_HOST__}/1.0/entities/actions/system/user/register/companyCode/search`
    const searchUrl = `${baseUrl}?query=${query}`
    return this.getJSON<CompanyCodeSearchResult[]>(searchUrl).catch((e) => {
      return []
    })
  }

  public getGoogleMapsPlacesAutomComplete(input, options) {
    const autocomplete = new window.google.maps.places.AutocompleteService()
    return new Promise((resolve, reject) => {
      autocomplete.getPlacePredictions(
        {
          ...options,
          input,
        },
        (results, status) => resolve(results)
      )
    })
  }

  public getGoogleMapsPlace(placeId) {
    const geocoder = new window.google.maps.Geocoder()
    return new Promise((resolve, reject) => {
      geocoder.geocode(
        {
          placeId,
        },
        (results, status) => resolve(results)
      )
    })
  }

  public startRenditionBilling(entityIds) {
    const url = `${__API_HOST__}/1.0/entities/actions/document/renditionBilling`
    return this.postJSON(url, { entityIds })
  }

  public startRenditionBillingV2(entityIds) {
    const url = `${__API_HOST__}/1.0/entities/actions/rb2/billing`
    return this.postJSON(url, { entityIds })
  }

  public getRecord(id) {
    return this.getJSON(`${__API_HOST__}/1.0/entities/records/${id}`)
  }

  public getSchemasByUri(uris) {
    const normalizedUris = _.isArray(uris) ? uris : [uris]
    return this.postJSON(`${__API_HOST__}/1.0/entities/metadata/_mget`, normalizedUris)
  }

  private getOAuth2Token(body) {
    // NOTE: we are not posting a json. Django signin is weird, uses query params
    return new Promise((resolve, reject) => {
      $.post({
        url: `${__API_HOST__}/1.0/entities/actions/system/oauth2/token`,
        data: body,
        xhrFields: {
          withCredentials: true
       }
      }).then((data) => {
        this.handleOauthResponse(data)
        resolve(data)
      }, reject)
    })
  }

  public subscribeToEntityUpdates(ids: string[]): Promise<any> {
    const url = `${__API_HOST__}/1.0/entities/actions/system/modification/subscribe`
    return this.postJSON(url, {
      entityIds: ids
    })
  }

  public unsubscribeFromEntityUpdates(ids: string[]): Promise<any> {
    return this.postJSON(`${__API_HOST__}/1.0/entities/actions/system/modification/unsubscribe`, {
      entityIds: ids
    })
  }

  public getChangedEntitiesSince(sinceTimestamp = 0) {
    const since = new Date(sinceTimestamp).toISOString()
    return this.getJSON(`${__API_HOST__}/1.0/entities/actions/system/modification/changedSince?when=${since}`)
  }

  private handleOauthResponse(data) {
    const cookieToken = Cookie.get('Authorization')
    const apiToken = `Bearer ${data.access_token}`

    if (cookieToken != apiToken) {
      Cookie.set('Authorization', apiToken, { expires: 30 })
    }

    this.setupAuthorization(Cookie.get('Authorization'))
  }

  /**
   * Get all related documents for this sales invoice by getting
   * all the related documents to the sales order and stitch it
   * to invoice document from the sales invoice
   * @param salesInvoices
   */
  public salesInvoiceRelatedDocuments(salesInvoices) {
    const nonInvoiceDocsQuery = this.salesInvoiceRelatedDocumentsNonInvoiceQuery(salesInvoices)

    const invoiceDocsQuery = _.map(salesInvoices, (invoice) => {
      const invoiceId = _.get(
        invoice,
        'core_accounting_accountsReceivable_salesInvoice.invoiceDocument.entityId'
      )
      return _.isNil(invoiceId)
        ? Promise.resolve(null)
        : this.entityStore.findRecord(invoiceId)
    })

    const getOrderIds = (invoice) => {
      const associatedOrderId = _.get(
        invoice,
        'core_accounting_accountsReceivable_salesInvoice.order.entityId'
      )
      const childOrderIds = _.map(
        _.get(
          invoice,
          'core_accounting_accountsReceivable_consolidatedSalesInvoice.salesOrders',
          []
        ),
        'entityId'
      )

      const relatedOrderIds = [associatedOrderId, ...childOrderIds]

      return _.filter(relatedOrderIds, (id) => !_.isEmpty(id))
    }

    const relatedDocsQuery = [nonInvoiceDocsQuery, ...invoiceDocsQuery]

    return Promise.all(relatedDocsQuery).then((result) => {
      const allNonInvoices = _.map(_.head(result), 'data')
      const allInvoices = _.slice(result, 1)

      // stitch invoice doc and related non-invoice docs together
      // preserve the order of sales invoices
      return _.map(salesInvoices, (salesInvoice) => {
        const relatedDocs = []
        // invoice doc for this sales invoice
        const salesInvoiceId = _.get(
          salesInvoice,
          'core_accounting_accountsReceivable_salesInvoice.invoiceDocument.entityId'
        )
        const invoice = _.find(allInvoices, { uniqueId: salesInvoiceId })
        if (!_.isNil(invoice)) {
          relatedDocs.push(invoice)
        }

        // non-invoices for this sales invoice
        const relatedOrderIds = getOrderIds(salesInvoice)
        const nonInvoices = _.filter(allNonInvoices, (doc) => {
          return _.includes(relatedOrderIds, _.get(doc, 'document.salesOrder.entityId'))
        })
        return relatedDocs.concat(nonInvoices)
      })
    })
  }

  private salesInvoiceRelatedDocumentsNonInvoiceQuery(salesInvoices) {
    const DOCUMENT_TYPE = '/1.0/entities/metadata/document.json'

    const salesInvoiceWithOrder = _.reduce(
      salesInvoices,
      (result, salesInvoice) => {
        const orderId = _.get(
          salesInvoice,
          'core_accounting_accountsReceivable_salesInvoice.order.entityId'
        )
        if (!_.isNil(orderId)) {
          result.push({ entityId: orderId })
        }

        const childOrderIds = _.map(
          _.get(
            salesInvoice,
            'core_accounting_accountsReceivable_consolidatedSalesInvoice.salesOrders',
            []
          ),
          (edge) => {
            return { entityId: edge.entityId }
          }
        )
        return result.concat(childOrderIds)
      },
      []
    )

    if (_.isEmpty(salesInvoiceWithOrder)) {
      return Promise.resolve([])
    }

    const documentsDataSource = new EntityDataSource({
      entityType: DOCUMENT_TYPE,
      filters: [
        {
          entityType: DOCUMENT_TYPE,
          path: 'document.salesOrder',
          type: 'matchEdge',
          values: salesInvoiceWithOrder,
        },
        {
          path: 'mixins.active',
          type: 'notContainsEdge',
          values: [{ entityId: '11111111-0000-0000-0000-000000000051' }],
        },
      ],
    })

    return documentsDataSource.collection.find()
  }

  public getContainerActivePlan(containerId: string): Promise<string> {
    return this.getJSON(`${__API_HOST__}/1.0/entities/actions/storyboard/active/container/${containerId}`)
  }

  public searchStoryboardPlan({ longitude, latitude }) {
    return this.getJSON(
      `${__API_HOST__}/1.0/entities/actions/storyboard/query?latitude=${latitude}&longitude=${longitude}`
    )
  }

  public getComments(entityId, lastUpdated, limit) {
    const queryParams = `${entityId}?dateGreaterThan=${lastUpdated}&limit=${limit}`
    const url = `${__API_HOST__}/1.0/api/comments/fetch/${queryParams}`
    return this.getJSON(url)
  }

  public getApplicationStatus(): Promise<IApplicationStatusResponse> {
    return this.getJSON(getHealthStatusUrl(__API_HOST__))
  }

  public getMetabaseUrl(params): Promise<IMetabaseUrlResponse> {
    const url = `${__API_HOST__}/1.0/entities/actions/signedUrl/metabase`
    return this.postJSON(url, params)
  }

  public getInboxToken(): Promise<IInboxTokenResponse> {
    const url = `${__API_HOST__}/1.0/entities/actions/inbox/token`
    return this.getJSON(url)
  }

  public isUserFollowing(entityId: string): Promise<IInboxFollowingResponse> {
    const url = `${__API_HOST__}/1.0/entities/actions/inbox/follow/${entityId}`
    return this.getJSON(url)
  }

  public followEntity(entityId: string): Promise<IInboxFollowingResponse> {
    const url = `${__API_HOST__}/1.0/entities/actions/inbox/follow/${entityId}`
    return this.postJSON(url, {})
  }

  public unfollowEntity(entityId: string): Promise<IInboxFollowingResponse> {
    const url = `${__API_HOST__}/1.0/entities/actions/inbox/follow/${entityId}`
    return this.deleteJSON(url)
  }
}

const apis = new ApiImpl()
export default apis
