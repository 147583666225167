import Select, { InputActionMeta } from 'react-select'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { FramesManager } from 'shared-libs/components/view/frames-manager'

export type ReactSelectProps = React.ComponentProps<typeof Select>
export type SelectStyleProps = ReactSelectProps['styles']
export type SelectComponentProps = ReactSelectProps['components']

/**
 * @uiComponent
 */
export interface ISelectProps extends IBaseProps {
  frames?: FramesManager // TODO: move to IBaseProps, if safe
  autoFocus?: boolean
  dir?: string
  errorText?: string
  hasError?: boolean
  formula?: string
  labelFormula?: string
  isDisabledFormula?: string
  inputClassName?: string
  isDisabled?: boolean
  isLoading?: boolean
  isRequired?: boolean
  isErrorInline?: boolean
  isHorizontalLayout?: boolean
  isStatic?: boolean
  menuRenderer?: (option: any) => void
  onChange?: (value: any, option?: any) => void
  onInputChange?: (value: string, actionMeta?: InputActionMeta) => void
  onNewOptionClick?: (label: string) => void
  onSelectRef?: (ref: any) => void
  optionHeight?: number
  optionRenderer?: (option: any) => JSX.Element
  isAsync?: boolean
  isEditableInline?: boolean
  isEditableLabel?: boolean
  isNative?: boolean
  isValidNewOption?: (any) => boolean
  onFocus?: (event) => void
  onBlur?: (event) => void
  size?: string
  value?: any
  validator?: (option: any) => boolean
  onOpen?: () => void
  // the default filter only filters by value and label
  filterByFieldPaths?: string[]

  // React Select Props
  onSelectMenuChange?: any
  inputRenderer?: (inputProps: any) => JSX.Element
  optionMapper?: (option: any) => any
  isCreatable?: boolean
  showCreatableWithResults?: boolean
  clearable?: boolean
  loadingPlaceholder?: string
  multi?: boolean
  promptTextCreator?: (label: string) => string
  menuIsOpen?: boolean | undefined
  noResultsText?: string
  optionGroupPath?: string
  optionLabelPath?: string
  optionValuePath?: string
  options?: any[]
  searchable?: boolean
  searchPromptText?: string
  tabIndex?: string
  placeholder?: string
  noOptionsMessage?: string
  valueRenderer?: (option: any) => JSX.Element
  filterOption?: (option: any) => boolean

  showRemoveOption?: boolean
  removeOptionText?: string

  styles?: SelectStyleProps
  components?: SelectComponentProps
}

export const defaultProps: Partial<ISelectProps> = {
  clearable: false,
  dir: 'ltr',
  isLoading: false,
  isStatic: false,
  loadingPlaceholder: 'Loading...',
  noResultsText: 'No results found',
  optionLabelPath: 'label',
  optionValuePath: 'value',
  options: [],
  placeholder: 'Select',
  searchPromptText: 'Type to search...',
  searchable: true,
}
