import _ from 'lodash'
import React from 'react'
import { Classes } from '@blueprintjs/core'

import apis from 'browser/app/models/apis'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { EntityFormPanel } from 'browser/components/atomic-elements/organisms/entity/entity-form-panel'
import 'browser/components/atomic-elements/organisms/share-bar/_share-bar.scss'

import { EntityMapper, IMapping } from 'shared-libs/models/entity-mapping'

/**
 * @uiComponent
 */
interface IAddEntityProps extends IBaseProps {
  entity: any // the source/parent entity in which the new entity will be created from
  buttonText?: string

  schemaId: any // could be a plan uuid or schema uuid
  uiSchemaPath: any
  mappings?: IMapping[]
  defaultValue?: any
}

interface IAddEntityState {}

/**
 * Allow the creation of one entity type from another entity type via entity mappings
 * The mappings in the 'ui:entityAddButton' will be combined with the mappings entity.
 * An entry in the entity mappings could overwrite the value in 'schemaId' via 'mixins.active[x].entityId'
 *
 *    "buttonText": "Check In Driver",
 *    "schemaId": "cf96dc53-9659-4f4b-a2a7-2b2bee249466",
 *    "mappings": [
 *      {
 *        "source": "core_yms_carrierAppointment.facility",
 *        "destination": "core_yms_execution.facility"
 *      },
 *      {
 *        "source": "uniqueId",
 *        "destination": "core_yms_execution.appointment.entityId"
 *      },
 *      {
 *        "source": "core_calendar_appointment.name",
 *        "destination": "core_yms_execution.appointment.displayName"
 *      }
 *
 *    ],
 *    "type": "ui:entityAddButton"
 *  }
 */
export class EntityAddButton extends React.Component<IAddEntityProps, IAddEntityState> {
  public static defaultProps: Partial<IAddEntityProps> = {
    buttonText: 'Create Entity',
    uiSchemaPath: 'uiSchema.web.entityCreationPanel',
  }

  constructor(props) {
    super(props)
    this.state = {}
  }

  public render() {
    const { buttonText, style, className = Classes.INTENT_PRIMARY } = this.props
    return (
      <Button
        data-debug-id="openAddEntityPopoverButton"
        className={className}
        onClick={this.handleAddEntity}
        style={style}
      >
        {buttonText}
      </Button>
    )
  }

  private handleAddEntity = async () => {
    const { entity, schemaId, defaultValue = {}, mappings = [], uiSchemaPath } = this.props

    if (_.isEmpty(schemaId)) {
      return
    }

    const iMappings = this.toIMapping(mappings, defaultValue)
    const settings = entity?.getStore()?.api?.settings || {}

    // map the parent values into 'defaultValue' and use 'defaultValue' to
    // initialize the child
    await EntityMapper.create(apis, settings)
      .fromEntity(entity)
      .useMappings(iMappings)
      .to(defaultValue, [schemaId])
      .execute({
        settings,
      })

    const contextProps = {
      density: 'collapse',
      isHorizontalLayout: true,
    }

    // allow the mappings to overwrite the entity type
    const active =  _.last(defaultValue?.mixins?.active)  || {}
    const entityId = _.get(active, 'entityId', schemaId)

    await EntityFormPanel.openAndResolveDependencies({
      defaultValue,
      entityId: entityId,
      uiContext: contextProps,
      uiSchemaPath,
      settings,
    })
  }

  /**
   * Convert json prop into IMappings
   */
  private toIMapping = (mappings: any[], destination: any): IMapping[] => {
    const { entity } = this.props
    return mappings.map((entry) => ({
      source: entity,
      destination,
      sourcePath: entry.source,
      destinationPath: entry.destination,
      expression: entry.formula,
      conditional: entry.conditional,
    }))
  }
}
